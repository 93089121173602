import React, { useEffect, useRef, useState } from 'react'
import GeneralFormInput from './forms/GeneralFormInput'
import { AnimatePresence, motion } from 'framer-motion'
import SenseAi_CNC from "../images/SenseAi_CNC.png";
import backVerticalSenseAi from "../images/backVerticalSenseAi.webp";
import SenseAiGif from "../images/SenseAiGif.gif";
import AssetVisibility from "../images/AssetVisibility.webp";
import MachineInfoInputs from './MachineInfoInputs';
import {tzStrings} from '../data/TimezonesList'
import WorkShiftInputs from './forms/WorkShiftInputs'
import moment from 'moment';
import {useStripe, useElements, PaymentElement, CardElement} from '@stripe/react-stripe-js';
import { getData, postData } from '../ApiCalls/DataApis';
import { apiLink } from '../ApiCalls/ApisVariables';
import toast from 'react-hot-toast';
import SignatureCanvas from 'react-signature-canvas';

const countryList = [
    {
        name: 'United States',
        id: 'usa',
        index: 0
    },
    {
        name: 'Mexico',
        id: 'mx',
        index: 1
    },
    {
        name: 'Canada',
        id: 'ca',
        index: 2
    },
]

const internetOptions = [
    {
      id: '1',
      name:'router',
      description: 'I required 4G LTE internet for my devices'
    },
    {
      id: '2',
      name:'wifi',
      description: 'I will provide my own WiFi (please make sure you have a 2.4GHz WiFi Network available).'
    }
  ]

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        border: "1px solid #32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",

        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  

const emailCheck = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function UserInformationInput() {
    const [isDark, setIsDark] = useState(false)
    const [showShippingInputs, setShowShippingInputs] = useState(true)
    const [showWifiDiv, setShowWifiDiv] = useState(false)
    const [showThankYouMessage, setShowThankYouMessage] = useState(false)
    const [email, setEmail] = useState('')
    const [validEmail, setValidEmail] = useState(true)
    const emailRef = useRef(null)
    const [form, setForm] = useState({
        name: '',
        phone: '',
        companyName: '',
        zipCode: '',
        state: '',
        city: '',
        addressLine1: '',
        addressLine2: '',
        ein_number: '',
        wifiSsid: '',
        wifiPassword: '',
      })
    const [isFormValid, setIsFormValid] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(countryList[0])
    const [allCountryList, setAllCountryList] = useState([])
    // const [numberOfDevices, setNumberOfDevices] = useState(5)
    const [machineParams, setMachineParams] = useState(() => {
        return Array(0).fill(null).map(() => ({
          machine_uuid: '',
          machine_make: '',
          machine_model: '',
          machine_identifier: '',
          machine_department: '',
        }));
      });
    const [validMachineInputs, setValidMachineInputs] = useState(false)
    const [selectedInternet, setSelectedInternet] = useState('1')
    const [showPaymentInfo, setShowPaymentInfo] = useState(false)
    const [selectedTimezone, setSelectedTimezone] = useState('')
    const [workShiftList, setWorkShiftList] = useState([
        {
          shift_name:'',
          shift_days:[],
          shift_start_time:new Date().setHours(8, 0, 0, 0),
          shift_end_time:new Date().setHours(17, 0, 0, 0),
        }
      ]
    )
    const [validInternetShifts, setValidInternetShifts] = useState(false)
    const [loading, setLoading] = useState(false)
    const stripe = useStripe();
    const elements = useElements();
    const [termsConditions, setTermsConditions] = useState(false)
    const [errorMessage, setErrorMessage] = useState(null);
    const [isSignatureFilled, setIsSignatureFilled] = useState(false);
    const signatureRef = useRef();
    const [selectedDevices, setSelectedDevices] = useState('');
    const [skipMachineDetails, setSkipMachineDetails] = useState(false);

  

    useEffect(() => {
        
        const savedEmail = localStorage.getItem('onboarding-email');
        const savedCountry = localStorage.getItem('onboarding-country');
        const savedTimezone = localStorage.getItem('onboarding-timezone');
        const savedInternet = localStorage.getItem('selected-internet');
        const savedDeviceAmount = localStorage.getItem('selected-device_amount');
        // const wifiSsid = localStorage.getItem('wiFI-SsID');
        
        
        if (savedEmail) setEmail(savedEmail);
        if (savedTimezone) setSelectedTimezone(savedTimezone);
        if (savedInternet) setSelectedInternet(savedInternet);
        if (savedCountry) {
            let parsedCountry = JSON.parse(savedCountry)
            countryList.map((country,index) => {
                if(parsedCountry.id == country.id){
                    setSelectedCountry(countryList[index]);
                }
            })
        }
        if (savedDeviceAmount) setSelectedDevices(savedDeviceAmount);
      }, []);



    useEffect(() => {
        const savedForm = localStorage.getItem('form');
        let parsedForm = JSON.parse(savedForm)
        if(savedForm){
            setForm(parsedForm);
        }

        const savedMachineArray = localStorage.getItem('machineParams');
        // console.log('savedMachineArray', savedMachineArray)
        if(savedMachineArray){
            setMachineParams(JSON.parse(savedMachineArray));
        }

        const savedWorkShiftList = localStorage.getItem('workShiftList');
        // console.log('savedWorkShiftList', savedWorkShiftList)
        if(savedWorkShiftList){
            setWorkShiftList(JSON.parse(savedWorkShiftList));
        }
        
      }, []);

      useEffect(() => {
            checkFormValidity()
      }, [form])

    const checkFormValidity = () => {
        if(form){
            // Check if every machine object has all fields filled
            const requiredFields = ['name', 'phone', 'companyName', 'zipCode', 'state', 'city', 'addressLine1'];
            // const isValid = requiredFields.every((field) => form[field].trim() !== '');
            const isValid = requiredFields.every((field) => {
                const value = form[field];
            
                // Check if value is undefined or null
                if (value === undefined || value === null) {
                  return false;
                }
            
                // Ensure value is a string before calling trim()
                if (typeof value !== 'string') {
                  return false;
                }
            
                // Trim the value and check if it's not empty
                return value.trim() !== '';
              });
            setIsFormValid(isValid);
        }
    };




    useEffect(() => {
        setValidInternetShifts(false)
        if(selectedInternet == '2'){
            if(form.wifiSsid && form.wifiPassword && selectedTimezone &&  form.wifiSsid.length > 0 && form.wifiPassword.length > 0 && selectedTimezone.length > 0){
                if(workShiftList && Object.keys(workShiftList).length > 0){
                    setValidInternetShifts(true)
                    workShiftList.map(shift => {
                        if(shift.shift_name.length === 0 || Object.keys(shift.shift_days).length == 0){
                            setValidInternetShifts(false)
                        }
                    })
                }
            }
        }else {
            if(selectedTimezone.length > 0){
                if(workShiftList && Object.keys(workShiftList).length > 0){
                    setValidInternetShifts(true)
                    workShiftList.map(shift => {
                        if(shift.shift_name.length === 0 || Object.keys(shift.shift_days).length == 0){
                            setValidInternetShifts(false)
                        }
                    })
                }
            }
        }

        
        
    
       }, [selectedInternet, form, selectedTimezone, workShiftList])
    

    // useEffect(() => {
    //     if(Object.keys(workShiftList).length < 1){
    //         setWorkShiftList([...workShiftList, {
    //             shift_name:'',
    //             shift_days:[],
    //             shift_start_time:new Date().setHours(8, 0, 0, 0),
    //             shift_end_time:new Date().setHours(17, 0, 0, 0),
    //         }])
    //     } 
    // }, [workShiftList])
    
    

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (emailRef.current && !emailRef.current.contains(event.target)) {
                if ( emailCheck.test(email) ) {
                    setValidEmail(true) 
                }
                else {
                    console.log("INVALID email")
                    setValidEmail(false)
                }
            }
        };

        if (emailRef.current ) {
            if ( emailCheck.test(email) ) {
                setValidEmail(true) 
            }
            else {
                console.log("INVALID email")
                setValidEmail(false)
            }
        }

        document.addEventListener('keypress', handleClickOutside, true);
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('keypress', handleClickOutside, true);
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [validEmail, email])

    useEffect(() =>{
        let tempGroupBy = []
        countryList.map(type => {
            tempGroupBy.push({name: type.name, id: type.id})
        })
        setAllCountryList(tempGroupBy)
   }, [])

   useEffect(() => {
    if(machineParams && Object.keys(machineParams).length > 0){
        // setValidMachineInputs(false)
        // machineParams.map(machine => {
        //     if(machine.machine_make.length > 0 && machine.machine_model.length > 0 && machine.machine_identifier.length > 0 && machine.machine_department.length > 0){
        //         console.log('machine', machine)
        //         console.log('machine_make', machine.machine_make)
        //         // setValidMachineInputs(true)
        //     }
        // })
        
        checkMachineListValidity()
        
        localStorage.setItem('machineParams', JSON.stringify(machineParams));
    }

   }, [machineParams])

   const checkMachineListValidity = () => {
    const requiredFields = ['machine_model', 'machine_make', 'machine_identifier', 'machine_department'];
  
    const isValid = machineParams.every((machine) => {
      return requiredFields.every((field) => {
        const value = machine[field];
        return typeof value === 'string' && value.trim() !== '';
      });
    });
  
    setValidMachineInputs(isValid);
  };



    const handleChangeEmail = (event) => {
        setEmail(event.target.value)
        localStorage.setItem('onboarding-email', event.target.value);
        
        if ( emailCheck.test(event.target.value) ) {
            setValidEmail(true)
        }
    }

    const handleGeneralFormChange = (event) => {
        // Get the name of the field that caused this change event
        // Get the new value of this field
        const { name, value } = event.target;

        // Assign new value to the appropriate form field
        const updatedForm = {
            ...form,
            [name]: value
        };

        // console.log('Form changed: ', updatedForm);

        // Update state
        setForm(updatedForm);
        localStorage.setItem('form', JSON.stringify(updatedForm));

    }

    const handleChangeCountry = (e) => {
        setSelectedCountry(allCountryList[e.target.value])
        localStorage.setItem('onboarding-country', JSON.stringify(allCountryList[e.target.value]) );
    }



      // ********** Functions for Part Operations inputs **********
  let handleChangePartOperations = (i, e, id) => {
    // console.log('id', id)
    let newPartOperations = [...machineParams];
    
    if(id === 'machine_uuid'){
      newPartOperations[i]['machine_uuid'] = e
    }else if(id === 'machine_make_database'){
        newPartOperations[i]['machine_make'] = e
    }else if(id === 'machine_model_database'){
        newPartOperations[i]['machine_model'] = e
    }else{
        newPartOperations[i][e.target.name] = e.target.value
    }

    // console.log('newPartOperations[i][e.target.name]', newPartOperations[i][e.target.name])

    setMachineParams(newPartOperations);
    // THis is to save the machineParams in the browser
    localStorage.setItem('machineParams', JSON.stringify(newPartOperations));
  }

    
  let addPartOperationInput = () => {
    
    if(Object.keys(machineParams).length < 14){
        
        setMachineParams([...machineParams, {
          machine_uuid:'',
          machine_department:'',
          machine_make:'',
          machine_model:'',
          machine_identifier:'',
        }])
  
        // THis is to save the machineParams in the browser
          let params = [...machineParams, {
              machine_uuid:'',
              machine_department:'',
              machine_make:'',
              machine_model:'',
              machine_identifier:'',
            }]
        console.log('Object.keys(machineParams).length',Object.keys(params).length )
        let numberOfMachines = Object.keys(params).length
        setSelectedDevices(numberOfMachines);
        localStorage.setItem('selected-device_amount', numberOfMachines);
        localStorage.setItem('machineParams', JSON.stringify(params));
    }
        
  }

  let removePartOperationInput = (i) => {
      let newPartOperations = [...machineParams]
      newPartOperations.splice(i, 1)
      setMachineParams(newPartOperations)

      // THis is to save the machineParams in the browser
      localStorage.setItem('machineParams', JSON.stringify(newPartOperations));
  }

  // ********** Functions for Part Operations inputs**********


  const handleChangeTimezone = (e) => {
    setSelectedTimezone(e.target.value)
    localStorage.setItem('onboarding-timezone', e.target.value);
    }

    // ********** Functions for Work Shift Inputs **********
  let handleChangeWorkShiftInput = (i, e, id, data) => {
    // console.log('id', id)
    let newWorkShiftList = [...workShiftList];
    
    if(id && id === 'day-checkbox') {
      newWorkShiftList[i]['shift_days'] = data
    }else if(id && id === 'shift_times') {
        newWorkShiftList[i]['shift_start_time'] = data.shift_start_time
        newWorkShiftList[i]['shift_end_time'] = data.shift_end_time
    }else{
      // works for parts amount
      newWorkShiftList[i][e.target.name] = e.target.value
    }

    
    setWorkShiftList(newWorkShiftList);

    localStorage.setItem('workShiftList', JSON.stringify(newWorkShiftList));
  }
    
  let addWorkShiftInputFormFields = () => {
      setWorkShiftList([...workShiftList, {shift_name:'', shift_days:[], shift_start_time:new Date().setHours(8, 0, 0, 0), shift_end_time:new Date().setHours(17, 0, 0, 0),}])
  }

  let removeWorkShiftInputs = (i) => {
      let newWorkShiftList = [...workShiftList]
      newWorkShiftList.splice(i, 1)
      setWorkShiftList(newWorkShiftList)
      localStorage.setItem('workShiftList', JSON.stringify(newWorkShiftList));
  }

  // ********** Functions for Work Shift Inputs **********
  
  
  // ********** Functions Signature **********
  const handleClearSignature = () => {
    signatureRef.current.clear();
    // Set isSignatureFilled to false when the signature is cleared
    setIsSignatureFilled(false);
  };

  const handleExportSignature = () => {

    if (isSignatureEmpty()) {
      toast.error('Please provide a signature.');
      return;
    }

      const dataURL = signatureRef.current.toDataURL();
      
      // For now, you can log the data URL to the console
      console.log('Exported Signature:', dataURL);
  
      // If you want to download the signature as an image, you can create an anchor element
      // and set the data URL as its href attribute, then simulate a click to trigger the download.
      const downloadLink = document.createElement('a');
      downloadLink.href = dataURL;
      downloadLink.download = 'signature.png';
      downloadLink.click();
    };

    const isSignatureEmpty = () => {
      // Use the isEmpty method provided by react-signature-canvas
      return signatureRef.current.isEmpty();
    };

    const handleSignatureChange = () => {
      // Update isSignatureFilled when the signature is not empty
      setIsSignatureFilled(!isSignatureEmpty());
    };
  // ********** Functions Signature **********

  const handleInternetChange = (e) => {
        setSelectedInternet(e.target.value)
        localStorage.setItem('selected-internet', e.target.value);
    }

  
  
  const handleSubmit = async (event) => {
    
    
    // // We don't want to let default form submission happen here,
    // // which would refresh the page.
    event.preventDefault();
    var cardElement = elements.getElement('card');
    
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make  sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (!loading) {
        setLoading(true)
        getData(apiLink + '/v1/payment_methods/setup_intent')
        .then(async response => {
          if(response.ok ){
            const data = await response.json();
            const result = await stripe.confirmCardSetup(data.client_secret, {
              payment_method: {
                card: elements.getElement(CardElement),
              }
            });
            if (result.error) {
              
              toast.error(result.error.message);
              cardElement.clear();
              setLoading(false)
              // Display result.error.message in your UI.
            } else {
              toast.success('Card verified, sending to database');
              // The setup has succeeded. Display a success message and send
              // result.setupIntent.payment_method to your server to save the
              // card to a Customer

              let needsRouter = true
              if(selectedInternet =='2'){
                  needsRouter = false
              }
      
              let shift = workShiftList
            workShiftList.map((currentShift, i) => {

                var formattedStart = ""
                try {
                    formattedStart = moment(currentShift.shift_start_time).format('HH:mm:ss')
                } catch(e) {}
                try {
                    formattedStart = currentShift.shift_start_time.toLocaleTimeString()
                } catch(e) {}
                // console.log("formattedStart", formattedStart)
                shift[i].shift_start_time = formattedStart


                var formattedEnd = ""
                try {
                    formattedEnd = moment(currentShift.shift_end_time).format('HH:mm:ss')
                } catch(e) {}
                try {
                    formattedEnd = currentShift.shift_end_time.toLocaleTimeString()
                } catch(e) {}
                // console.log("formattedEnd", formattedEnd)
                shift[i].shift_end_time = formattedEnd
            })

      
              const signatureDataURL = signatureRef.current.toDataURL();
      
            //   let machine_parameters = []
            //     machineParams.map(machine => {
            //         if(machine.machine_make.length > 0 && machine.machine_model.length > 0 && machine.machine_identifier.length > 0 && machine.machine_department.length > 0 ){
            //             machine_parameters.push(machine)
            //         }
            //     })

              let machine_parameters = machineParams.map((machine, index) => {
                return {
                  machine_uuid: machine.machine_uuid || '',
                  machine_make: machine.machine_make || `Machine `,
                  machine_model: machine.machine_model || `#${index}`,
                  machine_identifier: machine.machine_identifier || `id-${index}`,
                  machine_department: machine.machine_department || 'Main Department',
                };
              });
              
      
              const customerJson = {
                  'email': email,
                  'name': form.name,
                  'phone': form.phone,
                  'company_name': form.companyName,
                  'zip_code': form.zipCode,
                  'country': selectedCountry.name, 
                  'state': form.state,
                  'city': form.city,
                  'address_line_1': form.addressLine1,
                  'address_line_2': form.addressLine2,
                  'ein_number': form.ein_number,
                  'router_required': needsRouter, // True or False
                  'wifi_ssid': form.wifiSsid,
                  'wifi_password': form.wifiPassword,
                  'machine_count': selectedDevices,
                  'machine_list': machine_parameters, // This will be a large JSON
                  'organization_time_zone': selectedTimezone,
                  'shifts': shift, // This will be a large JSON
                  'signature_image': signatureDataURL,
                  'payment_method': result.setupIntent.payment_method
              }
      

              postData(apiLink + '/v1/senseai-onboarding-form', customerJson) 
              .then(async response => {
                  const data = await response.json();
                  if(response.ok){
                  // Clear the Element:
                  cardElement.clear();
                  toast.success("New Organization has been successfully added!");
                  setLoading(false)
                  setShowThankYouMessage(true)
                  }
                  else{
                  try{
                      toast.error(data.message);
                      // Clear the Element:
                      cardElement.clear();
                      setLoading(false)
                  }catch (e){
                      // Clear the Element:
                    cardElement.clear();
                      toast.error(e.message);
                  }
                  }
              })
            }
            
          }
        });
      }
  };


  const handleChangeDeviceAmount = (event) => {
        let number = Number(event.target.value)
        setSelectedDevices(event.target.value);
        localStorage.setItem('selected-device_amount', number);
        
        if(Object.keys(machineParams).length < number){
            let addAmount = number - Object.keys(machineParams).length
            const newMachines = Array.from({ length: addAmount }, createEmptyMachine);
            setMachineParams((prevMachineArray) => [...prevMachineArray, ...newMachines])

        }else if(Object.keys(machineParams).length > number){
            setMachineParams((prevMachineArray) => prevMachineArray.slice(0, number));

        }
    };

    const createEmptyMachine = () => ({
        machine_uuid: '',
        machine_make: '',
        machine_model: '',
        machine_identifier: '',
        machine_department: '',
      });
  
    const handleSkipMachineDetails = () => {
        setShowWifiDiv(true)
        setSkipMachineDetails(true)

        
    }


  return (
    <div className='w-full scrollbar-hide'>
        <AnimatePresence mode='wait'>
            {showShippingInputs && 
                <motion.div
                        key='shippingInfoContainer'
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: -10, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                >
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-0 min-h-screen'>
                        <div className='hidden md:flex col-span-1 overflow-hidden z-0 bg-slate-100 min-h-screen pt-20 pl-10 pr-10'> 
                            <img src={'/SenseAi_CNC.png'}  alt='CNC SenseAi' className=' w-full h-[500px] lg:h-[600px] object-cover rounded-xl'   />
                            {/* <img src={SenseAi_CNC}  alt='CNC SenseAi' className=' w-full h-[500px] lg:h-[600px] object-cover rounded-xl'   /> */}
                        </div>
                        <div className='col-span-1 z-10 pt-20 pr-10 pl-4' >
                            <div className='flex flex-col w-full px-4'>
                                <div className='flex justify-center mt-2 mb-4'>
                                    <h1 className='text-2xl font-medium text-gray-600'>Customer Onboarding Form</h1>
                                </div>
                            </div>
                            <div className="pt-4 grid grid-cols-2  sm:grid-cols-2 gap-2 px-4">
                                <div>
                                    <GeneralFormInput label='Name' type='text' placeholder='' name='name' value={form && form.name ? form.name : ''} onChange={handleGeneralFormChange} />
                                </div>
                                <div>
                                    <label className={`block text-sm font-normal mb-2 text-gray-600 dark:text-gray-300 `} htmlFor={'email'}>
                                        {'Email'}
                                    </label>
                                    <input 
                                    className={` block bg-transparent w-full border rounded-md py-2 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300`} 
                                    ref={emailRef}
                                    label='Email' 
                                    type='email' 
                                    placeholder='' 
                                    name='email'
                                    id={'email'} 
                                    onChange={handleChangeEmail}
                                    value={email}
                                    />
                                    {!validEmail && email && 
                                    <div key={'invalidEmail'} className='pt-1 flex items-center'>
                                        <svg xmlns="http://www.w3.org/2000/svg" className={`text-rose-600 w-4 h-4`} fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                        </svg>

                                        <span className={`text-sm font-light text-rose-600 `} >&nbsp;Please enter a valid email</span>
                                    </div>
                                    }
                                </div>
                                <div>
                                    <GeneralFormInput label='Company Name' type='text' placeholder='' name='companyName' value={form && form.companyName ? form.companyName : ''} onChange={handleGeneralFormChange} />
                                </div>
                                <div>
                                    <GeneralFormInput label='Phone' type='tel' placeholder='' name='phone' value={form && form.phone ? form.phone : ''}  onChange={handleGeneralFormChange} />
                                </div>
                            </div>
                            <div className="pt-4 grid grid-cols-2  sm:grid-cols-2 gap-2 px-4">
                                <div className="flex justify-center">
                                    <div className=" w-full outline-none">
                                        <label className={`block text-sm font-normal mb-2 text-gray-600 dark:text-gray-300 `} htmlFor={'country'}>
                                            {'Country'}
                                        </label>
                                            <select value={selectedCountry.index} onChange={handleChangeCountry} name='country' label='country'  className="form-select form-select-sm shadow-iotflows-lg appearance-none block w-full
                                            px-3 py-2 text-sm font-normal text-gray-700 dark:text-gray-200 bg-transparent bg-clip-padding bg-no-repeat border-1 border-gray-300 dark:border-slate-400 rounded
                                            transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-50 focus:outline-none focus:shadow-blue-300" aria-label=".form-select-sm ">
                                                {/* <option value={''}  >{""}</option> */}
                                                {countryList.map((group, index) => (
                                                    <option key={group.id} value={index}  >{group.name}</option>
                                                    ))}
                                            </select>
                                    </div>
                                </div>   
                                <div>
                                    <GeneralFormInput label='Zip Code' type='text' placeholder='' name='zipCode'  value={form && form.zipCode ? form.zipCode : ''}  onChange={handleGeneralFormChange} />
                                </div>
                                <div>
                                    <GeneralFormInput label='State' type='text' placeholder='' name='state' value={form && form.state ? form.state : ''}  onChange={handleGeneralFormChange} />
                                </div>
                                <div>
                                    <GeneralFormInput label='City' type='text' placeholder='' name='city' value={form && form.city ? form.city : ''}  onChange={handleGeneralFormChange} />
                                </div>
                            </div>
                            <div className="pt-4 grid grid-cols-1 sm:grid-cols-1 gap-2 px-4">
                                <div>
                                    <GeneralFormInput label='Address Line 1' type='text' placeholder='' name='addressLine1' value={form && form.addressLine1 ? form.addressLine1 : ''} onChange={handleGeneralFormChange} />
                                </div>
                                <div>
                                    <GeneralFormInput label='Address Line 2 (optional)' type='text' placeholder='' name='addressLine2' value={form && form.addressLine2 ? form.addressLine2 : ''} onChange={handleGeneralFormChange} />
                                </div>
                                <div>
                                    <GeneralFormInput label='EIN Number (optional, allows you to get more devices in your free trial)' type='text' placeholder='' name='ein_number' value={form && form.ein_number ? form.ein_number : ''} onChange={handleGeneralFormChange} />
                                </div>
                            </div>
                            <div className={`flex py-2 items-center mt-4 border-t-[0px] border-gray-200 px-4`}>
                                <button
                                    className={`relative items-center ml-auto inline-flex rounded-full border border-transparent text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100 px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                                    onClick={() => setShowShippingInputs(false)}
                                    disabled={!isFormValid || email == ''   || !validEmail}
                                    // disabled={(form && form.name =='') || (form && form.phone == '') || (form && form.companyName =='') || (form && form.zipCode =='') || (form && form.state == '') || (form && form.city =='') || (form && form.addressLine1 == '') || email == ''   || !validEmail}
                                >
                                    Continue
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                    </svg>

                                </button>
                            </div>
                        </div>

                    </div>
                </motion.div>
            }
            {!showShippingInputs && !showWifiDiv &&
                <motion.div
                    key='MachineInfoContainer'
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-0 min-h-screen'>
                        <div className='hidden md:flex md:flex-col col-span-1 overflow-hidden z-0 bg-slate-100 min-h-screen pt-10 pl-10 pr-10'> 
                            <div className='flex justify-center text-center py-10'>
                                <h1 className='text-xl lg:text-xl font-medium text-gray-500 xl:px-24'>Gain visibility into your manufacturing processes like never before</h1>
                            </div>
                            <img src={'/AssetVisibility.png'}  alt='SenseAi Dashboard' className=' w-full object-contain '   />
                            {/* <img src={AssetVisibility}  alt='SenseAi Dashboard' className=' w-full object-contain rounded-xl'   /> */}
                        </div>
                        <div className='col-span-1 z-10 pt-10 pr-10 pl-4' >
                            <div>
                                <button className='text-blue-500 hover:text-blue-700 flex px-2 w-fit' onClick={() => setShowShippingInputs(true)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                    </svg>
                                    <span>Back</span>
                                </button>
                                <motion.div
                                    key='machineAmountContainer'
                                    initial={{ y: 10, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    exit={{ y: -10, opacity: 0 }}
                                    transition={{ duration: 0.2 }}
                                >
                                    <div className='w-full flex flex-col justify-center mt-10'>
                                        <div className={`text-2xl mx-auto font-medium ${isDark?'text-white':'text-gray-600'}`}>
                                            How many machines would you like to onboard?
                                        </div>
                                        <div className="max-w-md mx-auto mt-4">
                                                <select
                                                    id="device-select"
                                                    value={selectedDevices}
                                                    onChange={handleChangeDeviceAmount}
                                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 
                                                            focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                                >
                                                    <option value={''}>
                                                    </option>
                                                    {Array.from({ length: 15 }, (_, i) => (
                                                    <option key={i + 1} value={i + 1}>
                                                        {i + 1}
                                                    </option>
                                                    ))}
                                                </select>
                                        </div>
                                    </div>
                                </motion.div>
                                {selectedDevices && selectedDevices > 0 ? (
                                <motion.div
                                    key='machineListContainer'
                                    initial={{ y: 10, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    exit={{ y: -10, opacity: 0 }}
                                    transition={{ duration: 0.2 }}
                                    className=' pt-10'
                                >
                                    <div className='mb-2'>
                                        <button
                                            className={`w-full relative items-center flex justify-center rounded-full border border-transparent text-gray-900 bg-gray-100 hover:bg-gray-200 disabled:bg-gray-100 px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                                            onClick={handleSkipMachineDetails} 
                                        >
                                            Skip Machine Details
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className='flex flex-col px-4'>
                                        <span className={`text-lg font-medium ${isDark?'text-white':'text-gray-600'}`}>Machine Information</span>
                                        <span className={`text-base leading-4 ${isDark?'text-gray-200':'text-gray-500'}`}>
                                            Add the machines you will like to start with for your free trial
                                        </span>
                                    </div>
                                    <div className='pt-6'>
                                        <div className=' flex items-center w-full justify-around text-sm text-gray-600 px-4'>
                                            <div className='text-center'>Make</div>
                                            <div className='text-center'>Name</div>
                                            <div className='text-center'>Identifier</div>
                                            <div className='text-center'>Department</div>
                                        </div>
                                        {machineParams && Object.keys(machineParams).length > 0 && machineParams.map((operation, index) => (
                                            <div key={index}>
                                                <MachineInfoInputs operation={operation} index={index} handleChangePartOperations={handleChangePartOperations} removePartOperationInput={removePartOperationInput} />
                                            </div>
                                        ))}
                                    </div>
                                    {/* <div className='px-4 w-full flex py-2'>
                                        <div className={`text-blue-600 hover:text-blue-800 hover:bg-blue-100 text-base font-medium cursor-pointer border border-[0.01em] border-blue-500 rounded-full w-full text-center py-1 flex justify-center items-center`} onClick={addPartOperationInput}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 ">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                                            </svg>
                                            <span className='pl-1'>Add Machine</span>
                                        </div>
                                    </div> */}
                                    <div className={`flex py-2 items-center mt-4 border-t-[0px] border-gray-200 px-4`}>
                                        <button
                                            className={`relative items-center ml-auto inline-flex rounded-full border border-transparent text-gray-900 bg-gray-100 hover:bg-gray-200 disabled:bg-gray-100 px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-gray-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                                            onClick={handleSkipMachineDetails} 
                                        >
                                            Skip Machine Details
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                            </svg>
                                        </button>
                                        <button
                                            className={`relative items-center ml-2 inline-flex rounded-full border border-transparent text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100 px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                                            onClick={() => setShowWifiDiv(true)}
                                            disabled={!validMachineInputs}
                                        >
                                            Continue
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                            </svg>
                                        </button>
                                    </div>
                                </motion.div>

                                ):(
                                    <motion.div
                                    key='emptyListContainer'
                                    initial={{ y: 10, opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    exit={{ y: -10, opacity: 0 }}
                                    transition={{ duration: 0.2 }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>

                </motion.div>
            }
            {!showShippingInputs && showWifiDiv && !showPaymentInfo &&
                <motion.div
                    key='WifiInfoContainer'
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-0 min-h-screen'>
                        <div className='hidden md:flex md:flex-col col-span-1 overflow-hidden z-0 bg-slate-100 min-h-screen pt-20 pl-10 pr-10'> 
                            {/* <div className='flex justify-center text-center py-10'>
                                <h1 className='text-xl lg:text-xl font-medium text-gray-500 xl:px-24'>AI-Powered Machine Performance Monitoring</h1>
                            </div> */}
                            <img src={'/backVerticalSenseAi.png'}  alt='CNC SenseAi' className=' w-full max-w-[600px] object-contain rounded-xl  mx-auto'   />
                            {/* <img src={backVerticalSenseAi}  alt='CNC SenseAi' className=' w-full max-w-[600px] object-contain rounded-xl  mx-auto'   /> */}
                        </div>
                        <div className='col-span-1 z-10 pt-10 pr-10 pl-4' >
                            <div>
                                <button className='text-blue-500 hover:text-blue-700 flex px-2 w-fit' onClick={() => setShowWifiDiv(false)}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                    </svg>
                                    <span>Back</span>
                                </button>
                                <div className='px-4 pt-10'>
                                    {internetOptions && Object.keys(internetOptions).length > 0 && 
                                        internetOptions.map((role,index) => (
                                        <div key={role.id} className="form-check mt-2 flex">
                                            <input className={`form-check-input appearance-none rounded-full h-4 w-4 border ${isDark?'bg-slate-500 border-gray-300':'bg-white border-gray-300'} checked:bg-blue-600 checked:border-blue-500 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer`}
                                                type="radio" 
                                                value={role.id}
                                                id={role.id}
                                                checked={selectedInternet === role.id}
                                                onChange={handleInternetChange}
                                            />
                                                <label className={`form-check-label inline-block text-base  ${isDark?'text-gray-400':'text-gray-600'} text-wrap	`} htmlFor={role.id}>
                                                {role.description}
                                                </label>
                                        </div>

                                        ))
                                    }
                                </div>
                                {selectedInternet === '2' && 
                                    <motion.div
                                        key='wifiInfo'
                                        initial={{ y: 10, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        exit={{ y: -10, opacity: 0 }}
                                        transition={{ duration: 0.2 }}
                                    >
                                        <div className='px-4 pt-4'>
                                            <div>
                                                <GeneralFormInput label='WiFi SSID (case-sensitive)' type='text' placeholder='' value={form.wifiSsid} name='wifiSsid' onChange={handleGeneralFormChange} />
                                            </div>
                                            <div className='pt-4'>
                                                <GeneralFormInput label='WiFi Password (case-sensitive)' type='text' placeholder='' value={form.wifiPassword} name='wifiPassword' onChange={handleGeneralFormChange} />
                                            </div>

                                        </div>
                                    </motion.div>
                                }

                                <div className='flex flex-col space-y-4 mt-4 text-base px-4'>
                                    {/* Time zone dropdown */}
                                    <div className='flex flex-col sm:flex-row'>
                                        <div className='grow max-w-[160px] text-gray-600 dark:text-gray-400 my-auto text-sm font-medium '>Schedule Timezone</div>
                                        <div className='flex-1 '>
                                            <div className="flex justify-center">   
                                                <div className="my-3 w-full outline-none">
                                                    <select onChange={handleChangeTimezone} value={selectedTimezone}  aria-label="timezone-select" 
                                                    className="form-select form-select-sm shadow-iotflows-lg appearance-none block w-full max-w-[500px] py-2 px-0 sm:py-1 sm:px-2 text-sm
                                                    font-normal text-gray-700 dark:text-gray-200 bg-transparent dark:bg-slate-600 bg-clip-padding bg-no-repeat border-1 border-gray-300 dark:border-slate-400 rounded
                                                    transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-slate-50 focus:outline-none focus:shadow-blue-300
                                                    disabled:border-transparent disabled:shadow-none disabled:dark:border-transparent disabled:bg-transparent disabled:dark:bg-transparent disabled:text-gray-600 disabled:dark:text-gray-300 disabled:opacity-100 " >
                                                        <option disabled value=''> -- select a timezone -- </option>
                                                        {tzStrings.map((tz, index) => (
                                                            <option key={`${tz.label}`} value={tz.value}>{tz.label}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                    <div className='flex flex-col'>
                                        <div className='flex flex-col text-gray-600 dark:text-gray-400 text-sm font-medium'>
                                            <span>Work Schedule</span>
                                            <span className='italic pt-1 font-normal'>{`(Shifts can't overlap in time)`}</span>
                                        </div>
                                        <motion.div 
                                            key='workScheduleEdit'
                                            initial={{ y: 10, opacity: 0 }}
                                            animate={{ y: 0, opacity: 1 }}
                                            exit={{ y: -10, opacity: 0 }}
                                            transition={{ duration: 0.2 }} 
                                            className='flex-1 '
                                        >
                                            <div className='flex flex-col'>
                                                {workShiftList.map((shift, index) => (
                                                    <div key={index}>
                                                    <WorkShiftInputs shift={shift} index={index}  handleChangeWorkShiftInput={handleChangeWorkShiftInput} removeWorkShiftInputs={removeWorkShiftInputs} />
                                                    </div>
                                                ))}
                                                <div className='px-4 w-full flex pt-1'>
                                                    <div className={`dark:text-blue-400 dark:hover:text-blue-600 text-blue-600 hover:text-blue-800 text-base sm:text-sm font-medium cursor-pointer `} onClick={addWorkShiftInputFormFields}>
                                                        Add another shift
                                                    </div>
                                                </div>
                                            </div>
                                        
                                        </motion.div>
                                    </div>
                                </div>

                                <div className={`flex py-2 items-center mt-4 border-t-[0px] border-gray-200 px-4`}>
                                    <button
                                        className={`relative items-center ml-auto inline-flex rounded-full border border-transparent text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100 px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                                        onClick={() => setShowPaymentInfo(true)}
                                        disabled={!validInternetShifts}
                                    >
                                        Continue
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-4 h-4">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                </motion.div>
            }
            {showPaymentInfo && !showThankYouMessage &&
                <motion.div
                    key='PaymentContainer'
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-0 min-h-screen'>
                        <div className='hidden md:flex md:flex-col col-span-1 overflow-hidden z-0 bg-white min-h-screen pt-32 px-1 lg:px-10'> 
                            <video width="100%" height="100%" loop={false} muted={true} autoPlay={true} playsInline={true} >
                                <source src='/SenseAiShortVideo.mp4' type="video/mp4" />
                                Your browser does not support HTML5 video.
                            </video>  
                        </div>
                        <div className='col-span-1 z-10 pt-10 pr-10 pl-4' >
                            <button className='text-blue-500 hover:text-blue-700 flex px-2 w-fit' onClick={() => setShowPaymentInfo(false)}>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                                </svg>
                                <span>Back</span>
                            </button>
                            <div className='px-4 pt-4'>
                                <h2 className='text-xl font-semibold text-gray-600'>Subscription Pricing Notice</h2>
                                <p className='text-base font-normal text-gray-600 pt-2'>Before signing the document, please be aware of the subscription pricing that will take effect after your 30-day free trial:</p>
                                <ul className='list-disc pl-8 pt-2'>
                                    <li className='text-base font-normal text-gray-600'>Monthly Plan: <span className='font-bold'>$119/month/device</span> <span className='text-gray-500 font-normal italic'>{`(default)`}</span></li>
                                    <li className='text-base font-normal text-gray-600'>Yearly Plan: <span className='font-bold'>$99/month/device</span> billed yearly <span className='text-green-700 font-medium'>{`(16.8% discount)`}</span></li>
                                </ul>

                                <p className='text-sm font-normal text-gray-600 italic pt-2'>Kindly note that these prices do not include any discounts associated with your account. Following the trial period, your subscription will be billed monthly or annually based on your selected plan.</p>
                            </div>
                            <div className='pt-8 pb-4'>
                                <div className='px-4'>
                                    <label className={`block text-sm font-normal mb-2 text-gray-600`}>
                                    Your Name:
                                    </label>
                                    <input className={` block bg-transparent w-full border rounded-md py-2 px-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300`} 
                                    type="text" value={form.name} onChange={() => console.log('name change not allowed')}  disabled />
                                    <div className={`flex text-sm font-normal mb-2 mt-4 text-gray-600`}>
                                        Sign below:
                                    </div>
                                    <div className='bg-slate-100 rounded-lg my-2'>
                                    <SignatureCanvas
                                        ref={signatureRef}
                                        canvasProps={{ width: 400, height: 200, className: 'signature-canvas' }}
                                        onEnd={handleSignatureChange} // Use onEnd event to detect changes in the signature

                                    />
                                    </div>
                            
                                    <button className='px-2 py-1 border-[1px] rounded-lg border-gray-400 text-gray-500 mb-2' onClick={handleClearSignature}>Clear Signature</button>
                                    {/* <button onClick={handleExportSignature}>Export Signature</button> */}

                                    {isSignatureFilled && (
                                    <div className=''>
                                        <h3 className='text-base text-gray-600'>Your Signature:</h3>
                                        <p className='text-blue-500' style={{ fontFamily: 'cursive', fontSize: '1.5rem'}}>{form.name}</p>
                                    </div>
                                    )}
                                </div>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className='px-4 pt-4'>
                                    <div className={`border-[1px] px-2 py-1 rounded ${isDark? 'border-slate-600':'border-slate-300'}`}>
                                        <CardElement options={CARD_ELEMENT_OPTIONS}/>
                                    </div>
                                    <div className="flex items-center mt-6">
                                        <input 
                                            checked={termsConditions} 
                                            id="checked-termsConditions" 
                                            type="checkbox" 
                                            onChange={(e) => setTermsConditions(e.target.checked)}
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label htmlFor="checked-termsConditions" className={`ml-2 text-sm font-medium ${isDark?'text-gray-400':'text-gray-600'}`}>
                                            By clicking Submit, you have read and agree to the IoTFlows <a href='https://www.iotflows.com/policies/terms-of-use' target='_blank' className='text-blue-500'>Terms of Use</a> and <a href='https://www.iotflows.com/legal/fee-schedule' target='_blank' className='text-blue-500'>Fee Schedule</a>
                                        </label>
                                    </div>
                                        <div className={`flex p-4 px-5 items-center mt-4 border-t-[0px] ${isDark?' border-gray-700':'border-gray-200'}`}>
                                            <button 
                                                type='submit'
                                                className={`relative cursor-pointer ml-auto inline-flex rounded-full border border-transparent ${isDark?' text-blue-100 bg-blue-900 hover:bg-blue-800 disabled:bg-blue-700':'text-blue-900 bg-blue-100 hover:bg-blue-200 disabled:bg-blue-100'} px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:cursor-default `}
                                                disabled={ !stripe || !termsConditions || loading || !isSignatureFilled}
                                                >
                                                Submit
                                                {loading && 
                                                    <div >
                                                        <svg aria-hidden="true" className={`absolute top-1/4 right-1/3 ml-8 w-5 h-15 animate-spin dark:fill-white dark:text-gray-500 fill-blue-600 text-gray-300`} viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"></path>
                                                        </svg>
                                                    </div>
                                                }
                                            </button>
                                            </div>
                                    {errorMessage && <div>{errorMessage}</div>}
                                </div>
                            </form>

                        </div>
                    </div>
                </motion.div>
            }
            {showThankYouMessage &&
                <motion.div
                    key='ThankyouContainer'
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                >
                    <div className='flex flex-col justify-center text-center py-10'>
                        <h1 className='text-2xl font-medium text-gray-500 xl:px-24'>Thank you!</h1>
                        <p className='text-lg font-normal text-gray-500 xl:px-24'>Someone will reach out soon for next steps.</p>
                        <div className='mt-4'>
                            <button className='px-4 py-1 bg-blue-600 text-white text-base rounded-full'
                                onClick={()=> window.open("https://dashboard.iotflows.com/", "_blank")}
                            >
                                Navigate to Create Account
                            </button>
                        </div>
                    </div>
                    
                </motion.div>
            }
            
        </AnimatePresence>
        
    </div>
  )
}

export default UserInformationInput
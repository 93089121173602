import { useState, useEffect } from 'react';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Box from '@mui/material/Box';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { isMobile } from 'react-device-detect';


const shiftDaysArray = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

function ProjectPartInputs({shift, index, removeWorkShiftInputs, handleChangeWorkShiftInput}) {
    const [checkedDaysState, setCheckedDaysState] = useState(new Array(shiftDaysArray.length).fill(false))
    const [isDark, setIsDark] = useState(false);
    const [shiftTimes, setShiftTimes] = useState({
        shift_start_time: new Date().setHours(8, 0, 0, 0),
        shift_end_time: new Date().setHours(17, 0, 0, 0),
    });
    
    const popperSx = {
      "& .MuiPaper-root": {
        borderRadius:'10px',
        // color: isDark? "#fff": '',
        backgroundColor: "transparent"
        // backgroundColor: isDark? "#94a3b8": '#fff',
      },
      "& .MuiSvgIcon-root": {
        color: isDark? "#fff !important": '#475569 !important',
      },
      "& .css-eziifo": {
        color: isDark? "#dc2626": '',
        backgroundColor: isDark? "#475569": '',
      },
      "& .MuiTypography-root": {
        color: isDark? "#fff !important": '',
      },
      "& .MuiClockPicker-root": {
        backgroundColor: isDark? "#64748b": '#fff',
        color: isDark? "#dc2626": '#4b5563',
        borderRadius:'10px',
      },
      "& .MuiPickersClockNumber-clockNumber": {
        backgroundColor: isDark? "#dc2626": '',
        color: isDark? "#dc2626": '',
      },
    };
    

    useEffect(() => {
        let checkedDays = []
            if(checkedDaysState.includes(true)){
                shiftDaysArray.map((day, index) =>{
                    if(checkedDaysState[index]){
                        checkedDays.push(day)
                    }
                })
            }
       }, [checkedDaysState])

    useEffect(() => {
        if(shift && shift.shift_days){
            let checked = new Array(shiftDaysArray.length).fill(false)
             shift.shift_days.map(shift => {
                let index = shiftDaysArray.indexOf(shift); 
                checked[index] = true
             })
            setCheckedDaysState(checked)
            
        }
       }, [shift.shift_days])

    useEffect(() => {

        if(shift && shift.shift_start_time || shift.shift_end_time){
            var updatedTimes = {
                shift_start_time: new Date().setHours(0, 0, 0, 0),
                shift_end_time: new Date().setHours(0, 0, 0, 0),
            }
            if (typeof shift.shift_start_time === 'string' || shift.shift_start_time instanceof String){
                let startTime = shift.shift_start_time.split(':')
                let startHour = startTime[0] || 0
                let startMin = startTime[1] || 0
                let startSec = startTime[2]|| 0
                updatedTimes.shift_start_time = new Date().setHours(startHour, startMin, startSec, 0)
            }else{
                updatedTimes.shift_start_time = shift.shift_start_time
            }
            if (typeof shift.shift_end_time === 'string' || shift.shift_end_time instanceof String){
                let endTime = shift.shift_end_time.split(':')
                let endHour = endTime[0]|| 0
                let endMin = endTime[1]|| 0
                let endSec = endTime[2]|| 0
                
                updatedTimes.shift_end_time =  new Date().setHours(endHour, endMin, endSec, 0)
            }else{
                updatedTimes.shift_end_time =  shift.shift_end_time
            }
            setShiftTimes(updatedTimes)  
        }
       }, [shift.shift_start_time,shift.shift_end_time])

    const handleChangeDaysState = (position, e) => {
        let updatedCheckedState = checkedDaysState.map((item, index) =>
        index === position ? !item : item
        );
        setCheckedDaysState(updatedCheckedState)
        
        let days = []
        updatedCheckedState.map((item, index) =>
        item ? days.push(shiftDaysArray[index]) : null
        );
        handleChangeWorkShiftInput(index, e, 'day-checkbox', days)
        
    }

    const handleChangeShiftStart = (newValue) => {
        let date = new Date(newValue)

        let mins = date.getMinutes();
        // let rem = parseInt(mins / 30);
        // date.setMinutes(rem == 1 ? 30 : 0)
        let rem = Math.round(mins / 15);
        date.setMinutes(rem * 15)
        if(date){
            let newTimes = {
                shift_start_time:date, 
                shift_end_time: shiftTimes.shift_end_time
            }
            // let newTimes = {
            //     shift_start_time:newValue.$d.toLocaleTimeString('en-US',{ hour12: false }), 
            //     shift_end_time: shiftTimes.shift_end_time
            // }
            setShiftTimes({
                shift_start_time:date, 
                shift_end_time: shiftTimes.shift_end_time
            });
            setShiftTimes(newTimes);
            handleChangeWorkShiftInput(index, date, 'shift_times', newTimes)
        }
      };
      
    const handleChangeShiftEnd = (newValue) => {
        let date = new Date(newValue)

        let mins = date.getMinutes();
        // let rem = parseInt(mins / 30);
        // date.setMinutes(rem == 1 ? 30 : 0)
        let rem = Math.round(mins / 15);
        date.setMinutes(rem * 15)

        if(date){
            let newTimes = {
                shift_start_time:shiftTimes.shift_start_time, 
                shift_end_time:date
            }
            setShiftTimes(newTimes);
            handleChangeWorkShiftInput(index, date, 'shift_times', newTimes)
        }

      };
    
 
      
  return (
    <div className={`${index % 2 === 0 &&'bg-slate-100 dark:bg-slate-800 dark:bg-opacity-70'} px-2 py-4 border-b-[1px] border-dashed dark:border-gray-700 border-gray-300`}>
       <div className='flex flex-col'>
            <div className='w-full flex items-center'>
                <input 
                className={`block bg-transparent w-full border rounded-md py-1 px-3 shadow-sm focus:outline-none  focus:bg-transparent focus:ring-1 sm:text-sm dark:placeholder:text-gray-500 dark:text-gray-300 dark:border-gray-600 dark:focus:border-blue-800 dark:focus:ring-blue-800 placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300`} 
                    type={'text'}
                    name={'shift_name'}
                    placeholder={`Shift Name`}
                    value={shift.shift_name || ''}
                    onChange={(e) => handleChangeWorkShiftInput(index, e)}
                />
                <div className='ml-2 cursor-pointer items-center my-auto' onClick={() => removeWorkShiftInputs(index)}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-5 h-5 dark:text-gray-300 dark:hover:text-gray-500 text-gray-600 hover:text-gray-900`}>
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>

                </div>
            </div>
            <div className='grid grid-cols-2 gap-4 md:grid-cols-3  mt-6'>
                {shiftDaysArray.map((day, index)=>(
                    <div key={day} className="flex items-center mx-3 ">
                        <input id={`day-checkbox-${day}`} type="checkbox" checked={checkedDaysState[index]} onChange={(e) => handleChangeDaysState(index, e)}  className="w-4 h-4 text-blue-500 hover:-translate-y-px focus:ring-transparent bg-transparent outline-none rounded border-gray-200 border-[1px] shadow"/>
                        <label htmlFor={`day-checkbox-${day.id}`} className="ml-2 text-xs font-medium text-gray-600 dark:text-gray-400">{day}</label>
                    </div>
                ))} 
            </div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div className='flex flex-col  md:flex-row mt-6 md:space-x-10'>
                    <TimePicker
                        label="Shift Start"
                        value={shiftTimes.shift_start_time}
                        onChange={handleChangeShiftStart}
                        minutesStep={15}
                        PopperProps={{
                            sx: popperSx
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                            <Box sx={{ display: 'flex-col', alignItems: 'center', svg: { color: isDark? '#d1d5db' : "#4b5563", }, }}>
                                <label className={`block text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`} >
                                    Shift Start
                                </label>
                                <div className='flex items-center'>
                                <input 
                                    className={`block bg-transparent w-full border rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                                    ref={inputRef} 
                                    {...inputProps}
                                    disabled={!isMobile}  />
                                    <div className='ml-[-6px] text-gray-600' style={{color:'#475569'}}>{InputProps?.endAdornment}</div>
                                    
                                </div>
                            
                            </Box>
                        )}
                    /> 
                    <TimePicker
                        label="Shift End"
                        value={shiftTimes.shift_end_time}
                        onChange={handleChangeShiftEnd}
                        minutesStep={15}
                        PopperProps={{
                            sx: popperSx
                        }}
                        renderInput={({ inputRef, inputProps, InputProps }) => (
                            <Box sx={{ display: 'flex-col', alignItems: 'center', svg: { color: isDark? '#d1d5db' : "#4b5563", }, }}>
                                <label className={`block text-sm font-medium mb-2 ${isDark?'text-gray-300':'text-gray-600'}`} >
                                    Shift End
                                </label>
                                <div className='flex items-center'>
                                <input 
                                    className={` block bg-transparent w-full border rounded-md py-2 pl-3 pr-3 shadow-sm focus:outline-none focus:bg-transparent focus:ring-1 sm:text-sm ${isDark?'placeholder:text-gray-500 text-gray-300 border-gray-600 focus:border-blue-800 focus:ring-blue-800':'placeholder:text-gray-400 text-gray-600 border-gray-300 focus:border-blue-300 focus:ring-blue-300'}`} 
                                    ref={inputRef} 
                                    {...inputProps}
                                    disabled={!isMobile} />
                                    <div className='ml-[-6px]'>{InputProps?.endAdornment}</div>
                                    
                                </div>
                            
                            </Box>
                        )}
                    /> 
                </div>
            </LocalizationProvider>
            
            

        </div>
        
    </div>
  )
}

export default ProjectPartInputs